import React from 'react';

// import icons from 'icons';
import * as feather from 'feather-icons';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import './icon.scss';

class Icon extends React.Component {
  getAttrs(icon) {
    const attrs = {};
    for (const attr in feather.icons[icon].attrs) {
      let newAttr;
      if (attr === 'class') {
        newAttr = 'className';
      } else {
        newAttr = _.camelCase(attr);
      }
      attrs[newAttr] = feather.icons[icon].attrs[attr];
    }
    return attrs;
  }

  loadFeather(icon) {
    const attrs = this.getAttrs(icon);
    const contents = feather.icons[icon].contents;
    return (
      <svg
        {...attrs}
        {...this.props}
        dangerouslySetInnerHTML={{
          __html: contents,
        }}
      />
    );
  }

  render() {
    let icon, modifier;
    // eslint-disable-next-line
    const attrs = this.getAttrs('flag'); // Load default SVG attrs from some random feather icon
    if (feather.icons[this.props.icon]) {
      icon = this.loadFeather(this.props.icon);
    }
    if (this.props.modifier) {
      if (feather.icons[this.props.modifier]) {
        modifier = this.loadFeather(this.props.modifier);
      }
    }

    return (
      <span
        className={`bk-icon ${
          this.props.className ? this.props.className : ''
        }`}
        data-tip={this.props['data-tip']}
        data-for={this.props['data-for']}
      >
        <span className="bk-icon-main">{icon}</span>
        {modifier && (
          <span
            className="bk-icon-modifier"
            // option to override background colour so modifier icons can work on different colour backgrounds
            style={
              this.props.backgroundColor
                ? { 'background-color': this.props.backgroundColor }
                : {}
            }
          >
            {modifier}
          </span>
        )}
        {this.props.badge === 'true' && <span className="bk-icon__badge" />}
      </span>
    );
  }
}

Icon.propTypes = {
  icon: PropTypes.string,
  modifier: PropTypes.string,
  backgroundColor: PropTypes.string,
  badge: PropTypes.string,
};

export default Icon;
