import { produce } from 'immer';
import _ from 'lodash';

import api from '../utils/api';

const initialState = {
  homeModal: {
    open: false,
    step: 1,
  },
  licenceModalOpen: false,
  licenseAgreementModalOpen: false,
  publisherModalOpen: false,
  publisherSignUpFormValues: {},
  uploadModalOpen: false,
  pricingHelperModalOpen: false,
};

// ACTION TYPES
const OPEN_MODAL = 'OPEN_MODAL';
const SET_HOME_MODAL_STEP = 'SET_HOME_MODAL_STEP';
const CLOSE_MODAL = 'CLOSE_MODAL';
const OPEN_LICENCE_MODAL = 'OPEN_LICENCE_MODAL';
const CLOSE_LICENCE_MODAL = 'CLOSE_LICENCE_MODAL';
const OPEN_PRICING_HELPER_MODAL = 'OPEN_PRICING_HELPER_MODAL';
const CLOSE_PRICING_HELPER_MODAL = 'CLOSE_PRICING_HELPER_MODAL';
const OPEN_LICENCE_AGREEMENT_MODAL = 'OPEN_LICENCE_AGREEMENT_MODAL';
const CLOSE_LICENCE_AGREEMENT_MODAL = 'CLOSE_LICENCE_AGREEMENT_MODAL';
const OPEN_PUBLISHER_MODAL = 'OPEN_PUBLISHER_MODAL';
const SAVE_PUBLISHER_DATA = 'SAVE_PUBLISHER_DATA';
const CLEAR_PUBLISHER_DATA = 'CLEAR_PUBLISHER_DATA';
const CLOSE_PUBLISHER_MODAL = 'CLOSE_PUBLISHER_MODAL';
const OPEN_UPLOAD_MODAL = 'OPEN_UPLOAD_MODAL';
const CLOSE_UPLOAD_MODAL = 'CLOSE_UPLOAD_MODAL';
const SET_UPLOAD_FILE = 'SET_UPLOAD_FILE';

// SYNC ACTION CREATORS

export function setHomeModalStep({ step = 1 }) {
  return { type: SET_HOME_MODAL_STEP, step };
}
export function openModal() {
  return { type: OPEN_MODAL };
}

export function closeModal() {
  return { type: CLOSE_MODAL };
}

export function openLicenceModal() {
  return { type: OPEN_LICENCE_MODAL };
}

export function closeLicenceModal() {
  return { type: CLOSE_LICENCE_MODAL };
}

export function openPricingHelperModal() {
  return { type: OPEN_PRICING_HELPER_MODAL };
}

export function closePricingHelperModal() {
  return { type: CLOSE_PRICING_HELPER_MODAL };
}

export function openLicenceAgreementModal() {
  return { type: OPEN_LICENCE_AGREEMENT_MODAL };
}

export function closeLicenceAgreementModal() {
  return { type: CLOSE_LICENCE_AGREEMENT_MODAL };
}

export function openPublisherModal(values) {
  return { type: OPEN_PUBLISHER_MODAL, values };
}

export function savePublisherData(values) {
  return { type: SAVE_PUBLISHER_DATA, values };
}

export function clearPublisherData() {
  return { type: CLEAR_PUBLISHER_DATA };
}

export function closePublisherModal() {
  return { type: CLOSE_PUBLISHER_MODAL };
}
export function openUploadModal() {
  return { type: OPEN_UPLOAD_MODAL };
}
export function closeUploadModal() {
  return { type: CLOSE_UPLOAD_MODAL };
}
export function setUploadFile(file) {
  return { type: SET_UPLOAD_FILE, file };
}

// ASYNC ACTION CREATORS
export async function uploadHubspotFile(file, publisherName, transform = true) {
  try {
    const res = await api.hubspotUpload(file, publisherName, transform);
    return setUploadFile(res.url);
  } catch (err) {
    console.error('Error uploading file ', err);
    return setUploadFile('');
  }
}

// REDUCER
const app = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case OPEN_MODAL: {
        draft.homeModal = {
          ...draft.homeModal,
          open: true,
        };
        break;
      }
      case CLOSE_MODAL: {
        draft.homeModal = {
          ...draft.homeModal,
          open: false,
        };
        break;
      }
      case SET_HOME_MODAL_STEP: {
        draft.homeModal = {
          ...draft.homeModal,
          step: _.get(action, 'step', 1),
        };
        break;
      }
      case OPEN_LICENCE_MODAL: {
        draft.licenceModalOpen = true;
        break;
      }
      case CLOSE_LICENCE_MODAL: {
        draft.licenceModalOpen = false;
        break;
      }
      case OPEN_PRICING_HELPER_MODAL: {
        draft.pricingHelperModalOpen = true;
        break;
      }
      case CLOSE_PRICING_HELPER_MODAL: {
        draft.pricingHelperModalOpen = false;
        break;
      }
      case OPEN_LICENCE_AGREEMENT_MODAL: {
        draft.licenceAgreementModalOpen = true;
        break;
      }
      case CLOSE_LICENCE_AGREEMENT_MODAL: {
        draft.licenceAgreementModalOpen = false;
        break;
      }
      case OPEN_PUBLISHER_MODAL: {
        draft.publisherModalOpen = true;
        draft.publisherSignUpFormValues = {
          ...draft.publisherSignUpFormValues,
          ...action.values,
        };
        break;
      }
      case SAVE_PUBLISHER_DATA: {
        draft.publisherSignUpFormValues = {
          ...draft.publisherSignUpFormValues,
          ...action.values,
        };
        break;
      }
      case CLEAR_PUBLISHER_DATA: {
        draft.publisherSignUpFormValues = {};
        break;
      }
      case CLOSE_PUBLISHER_MODAL: {
        draft.publisherModalOpen = false;
        break;
      }
      case OPEN_UPLOAD_MODAL:
        draft.uploadModalOpen = true;
        break;
      case CLOSE_UPLOAD_MODAL:
        draft.uploadModalOpen = false;
        break;
      case SET_UPLOAD_FILE: {
        draft.publisherSignUpFormValues = {
          ...draft.publisherSignUpFormValues,
          file: action.file,
        };
        break;
      }
      default: {
        return draft;
      }
    }
  });
};

export default app;
