import React, { Component } from 'react';

import { Link } from 'gatsby';
import { Cookies } from 'react-cookie';

import './cookie-banner.scss';
import Icon from './icon';

export default class CookieBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accepted: null,
    };
  }

  componentDidMount() {
    const cookies = new Cookies();
    const accepted = cookies.get('accepted-cookie', { path: '/' });
    this.setState({ accepted });
  }

  setCookieConsent(accepted) {
    const cookies = new Cookies();
    cookies.set('accepted-cookie', accepted, { path: '/' });
    this.setState({
      accepted,
    });
  }

  render() {
    if (this.state.accepted) {
      return null;
    }
    return (
      <div
        className={`cookie-banner ${
          this.state.accepted === undefined ? 'show' : 'hide'
        }`}
      >
        <div className="cookie-banner-container">
          <p>
            We use cookies to ensure you get the best experience. If you
            continue to use this site, we will assume that you accept cookies.
            You can find out more in our{' '}
            <Link to="/privacy-policy" target="_blank">
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link to="/cookies-policy" target="_blank">
              Cookies Policy
            </Link>
            .
          </p>
        </div>
        <Icon
          icon="x"
          role="button"
          className="cookie-banner-dismiss"
          onClick={() => this.setCookieConsent(true)}
        />
      </div>
    );
  }
}
