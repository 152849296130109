import { produce } from 'immer';

const initialState = {
  dark: false,
};

const TOGGLE_THEME = 'TOGGLE_THEME';

export function toggleTheme() {
  return { type: TOGGLE_THEME };
}

const rendering = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case TOGGLE_THEME: {
        draft.dark = !draft.dark;
        break;
      }
      default:
        break;
    }
  });
};
export default rendering;
