import axios from 'axios';
import axiosRetry from 'axios-retry';
import FormData from 'form-data';
import * as mime from 'mime-types';

import { getAuthHeader } from './auth';

axiosRetry(axios);
class API {
  constructor() {
    this.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    this.isRefreshing = false;
  }

  async request(method, url, params, data, authHeaderOverride) {
    try {
      const response = await axios.request({
        method,
        url: `/api/v1${url}`,
        params,
        data,
        headers: Object.assign(this.headers, {
          Authorization: authHeaderOverride || getAuthHeader(),
          'X-bk-ignore-events':
            localStorage.getItem('ignore_events') || 'false',
        }),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  async refreshAuth() {
    this.isRefreshing = true;
    // refreshDeferred can be used externally to wait on the currently running refresh request
    this.refreshDeferred = new Promise(async (resolve, reject) => {
      if (!localStorage.getItem('refresh_token')) {
        this.isRefreshing = false;
        return reject();
      }
      try {
        const tokens = await this.request(
          'POST',
          '/auth/refresh',
          null,
          null,
          `Bearer ${localStorage.getItem('refresh_token')}`
        );
        localStorage.setItem('access_token', tokens.access_token);
        localStorage.setItem('refresh_token', tokens.refresh_token);
        this.isRefreshing = false;
        return resolve();
      } catch (err) {
        this.isRefreshing = false;
        return reject(err);
      }
    });
    return this.refreshDeferred;
  }

  async authenticate(credentials) {
    const user = await this.request('POST', '/auth', null, {
      username: credentials.username,
      password: credentials.password,
    });
    localStorage.setItem('access_token', user.access_token);
    localStorage.setItem('refresh_token', user.refresh_token);
    return user;
  }

  async searchBlog(searchTerm) {
    try {
      const search = await axios.get(
        `https://api.hubapi.com/contentsearch/v2/search?portalId=${process.env.GATSBY_HUBSPOT_PORTAL_ID}&term=${searchTerm}&type=BLOG_POST`
      );
      return search;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  /**
   * createPublisher is called after step 2 of the publisher sign up process &
   * creates a record in the `bk_users` for the new publisher
   * @param {Object} data
   * @returns
   */
  async createPublisher(data) {
    return await this.request('POST', `/users/publisherUser`, null, data);
  }

  /**
   * createPublisherAccount is called after acceptance of the terms and
   * conditions during the publisher sign up process
   * @param {Object} data
   * @returns
   */
  createPublisherAccount(data) {
    return this.request('POST', `/users/publisherUserAccount`, null, data);
  }

  /**
   * getQuotes fetch the quote generated for a specific submission
   * during the DSP licensing sign up process
   * @param {String} email
   * @returns
   */
  async getQuotes(email) {
    try {
      const quotes = await axios.get(`/api/v1/licences/quotes/${email}`, {
        headers: Object.assign(this.headers, {
          Authorization: getAuthHeader(),
          'X-bk-ignore-events':
            localStorage.getItem('ignore_events') || 'false',
        }),
        'axios-retry': {
          retries: 6,
          retryCondition: () => true,
          retryDelay: axiosRetry.exponentialDelay,
        },
      });
      return quotes.data;
    } catch (error) {
      throw error.response;
    }
  }

  /**
   * getReportingQuote fetches the reporting quote generated for a submission
   * during the DSP licensing sign up process
   * @param {String} email
   * @returns
   */
  async getReportingQuote(email) {
    try {
      const quotes = await axios.get(
        `/api/v1/licences/quotes/reporting-only/${email}`,
        {
          headers: Object.assign(this.headers, {
            Authorization: getAuthHeader(),
            'X-bk-ignore-events':
              localStorage.getItem('ignore_events') || 'false',
          }),
          'axios-retry': {
            retries: 6,
            retryCondition: () => true,
            retryDelay: axiosRetry.exponentialDelay,
          },
        }
      );
      return quotes.data;
    } catch (error) {
      throw error.response;
    }
  }

  /**
   * acceptQuote accepts an option of the quote generated for a specific publisher
   * during the DSP licensing sign up process, updates Hubspot
   * @param {String} email
   * @returns
   */
  async acceptQuote(quoteId, batchId) {
    return this.request(
      'PUT',
      `/licences/quotes/${quoteId}?batchId=${batchId}`,
      null
    );
  }

  /**
   * updatePublisher updates a publisher record in the `bk_users` table, it is
   * called as we collect more data about the publisher during the final stages
   * of sign up
   * @param {Object} data
   * @returns
   */
  updatePublisher(data) {
    return this.request('PUT', `/users/publisherUser`, null, data);
  }

  createStreamingLicence(data) {
    return this.request('POST', '/licences', null, data);
  }

  getStreamingLicence(uri) {
    return this.request('POST', `/licences/contract`, null, { uri });
  }

  /**
   * Upload a file to hubspot files
   * @param {File} file
   * @param {String} filename
   * @param {Boolean} transform true if image should be transformed to a square
   * profile image before upload
   * @returns
   */
  hubspotUpload(file, filename, transform) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', filename);
    if (transform) {
      formData.append('transform', 'transform');
    }
    return this.request(
      'POST',
      '/data/upload-hubspot',
      { type: 'publisherLogo' },
      formData
    );
  }

  /**
   * Get a signed URL for uploading a file to S3
   */
  getDataUploadURL(filenames, path, contentType = false, type = 'FTP') {
    let contentTypeString = ``;
    if (contentType) {
      contentTypeString = `&contentType=${contentType}`;
    }
    return this.request(
      'GET',
      `/data/upload-url?${filenames
        .map((filename) => `filename=${filename}`)
        .join('&')}&path=${path}&type=${type}${contentTypeString}`
    );
  }

  /**
   * Get a signed URL for accessing a file in S3
   */
  async getDataObjectURL(filename) {
    return await this.request('GET', `/data/object-url?filename=${filename}`);
  }

  /**
   * Upload a file through a pre-signed S3 url
   * @param {string} url
   * @param {File} file
   * @returns
   */
  uploadS3FileDirect(url, file) {
    const contentType = mime.lookup(file.name) || 'application/octet-stream';
    return axios.put(url, file, {
      headers: { 'Content-Type': contentType },
    });
  }

  /**
   * Upload a file to S3 by calling to the web-api
   * @param {string} filename
   * @param {File} file
   * @param {Boolean} transform whether to transform the image, used for display
   * pictures uploaded during publisher sign up
   * @param {String} type determines which S3 bucket the file will be uploaded
   * to. `USER_IMAGE`, `FTP` etc
   * @returns
   */
  uploadS3File(filename, file, transform = true, type = 'USER_IMAGE') {
    const formData = new FormData();
    const contentType = mime.lookup(file.name) || 'application/octet-stream';
    formData.append('file', file);
    formData.append('filename', filename);
    formData.append('type', type);
    formData.append('contentType', contentType);
    if (transform) {
      formData.append('transform', 'transform');
    }
    return this.request('POST', '/data/upload-s3', {}, formData);
  }

  /**
   * Submit publisher data from songwriter wizard for CSV creation
   * @param {array} songsData Data from song wizard
   * @param {string} url s3Path to send generated csv file to
   * @param {string} email Submitter email
   * @returns
   */
  async submitSongData(songsData, url, email) {
    return await this.request('POST', `/data/publisher-wizard-json`, null, {
      s3Path: url,
      data: songsData,
      email,
    });
  }
}

export default new API();
