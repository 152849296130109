import LogRocket from 'logrocket';
import { applyMiddleware, createStore as reduxCreateStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from '../state';

const createStore = () =>
  reduxCreateStore(
    rootReducer,
    applyMiddleware(thunk, logger, LogRocket.reduxMiddleware())
  );

export default createStore;
