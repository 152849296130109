export function hasAuthToken() {
  return (
    localStorage.getItem('access_token') !== null &&
    localStorage.getItem('access_token') !== 'null'
  );
}

export function getAuthHeader() {
  return 'Bearer ' + localStorage.getItem('access_token');
}
