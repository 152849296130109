import { produce } from 'immer';

import api from '../utils/api';

const initialState = {
  publisher: {},
  serviceMeta: {},
  file: '',
};

// ACTION TYPES
const SET_PUBLISHER = 'SET_PUBLISHER';
const SET_SERVICE_META = 'SET_SERVICE_META';
const SET_CONTRACT = 'SET_CONTRACT';

// SYNC ACTION CREATORS
export function setPublisher(publisher) {
  return { type: SET_PUBLISHER, payload: publisher };
}

export function setServiceMeta(meta, merge = true) {
  return { type: SET_SERVICE_META, payload: meta, merge };
}

export function setContract(uri) {
  return { type: SET_CONTRACT, payload: uri };
}

// ASYNC ACTION CREATORS
export function createStreamingLicence(data) {
  return async (dispatch) => {
    try {
      const { uri } = await api.createStreamingLicence(data);
      const { file } = await api.getStreamingLicence(uri);
      dispatch(setContract(file));
    } catch (err) {
      console.error('Error creating licence: ', err);
      dispatch(setContract(''));
      throw err;
    }
  };
}

// REDUCER
const licensing = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_PUBLISHER: {
        draft.publisher = action.payload;
        break;
      }
      case SET_SERVICE_META: {
        if (action.merge) {
          draft.serviceMeta = { ...draft.serviceMeta, ...action.payload };
        } else {
          draft.serviceMeta = { ...action.payload };
        }
        break;
      }
      case SET_CONTRACT: {
        draft.file = action.payload;
        break;
      }
      default: {
        return draft;
      }
    }
  });
};

export default licensing;
