import { Component } from 'react';

export default class HubspotChat extends Component {
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', '//js.hs-scripts.com/3796264.js');
    script.setAttribute('id', 'hs-script-loader');
    script.setAttribute('type', 'text/javascript');
    script.defer = true;
    head.appendChild(script);
  }

  componentWillUnmount() {
    const script = document.getElementById('hs-script-loader');
    script.parentNode.removeChild(script);
  }

  render() {
    return null;
  }
}
